
import { Component, Vue, Watch } from 'vue-property-decorator'
import { MenuItem } from '@/types/menu'
import { KeepAlive } from '@/utils/decorators'

export interface Route {
  path: string;
}
@Component
@KeepAlive
export default class Index extends Vue {
  private radio = ''
  private menuPath = ''
  private radioList: Array<MenuItem> = []

  @Watch('$route')
  routeChange () {
    this.init()
  }

  created () {
    this.init()
  }

  init () {
    const list = sessionStorage.getItem('childNode') || ''
    this.radio = this.$route.path
    this.menuPath = '/' + this.$route.path.split('/')[1]
    this.radioList = JSON.parse(list).childNode
    if (this.radio.lastIndexOf('/') === 0) {
      this.handleChange(this.radioList[0].url)
    }
  }

  handleChange (url: string) {
    this.$router.replace({ path: url })
  }
}
